import React from 'react';
import './Spinner.css';
import spinner from './Spinner.jpg'; // Ensure the path is correct

const Spinner = () => {
  return (
    <div className="spinner-container">
      <img src={spinner} alt="Loading..." className="spinner" />
      <p className="loading-text">Loading...</p>
    </div>
  );
};

export default Spinner;
