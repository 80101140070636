// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './AuthContext';
import { UserProvider } from './UserContext';
import { CartProvider } from './CartContext';
import { logPageView } from './analytics';
import ComingSoon from './ComingSoon'; // Import the new Coming Soon component
import SplashScreen from './SplashScreen';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [fadeSplash, setFadeSplash] = useState(false);

  useEffect(() => {
    const slideTimer = setTimeout(() => {
      setFadeSplash(true);
    }, 3000);

    const hideTimer = setTimeout(() => {
      setShowSplash(false);
    }, 4500);

    return () => {
      clearTimeout(slideTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  if (showSplash) {
    return <SplashScreen isVisible={!fadeSplash} />;
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <UserProvider>
          <CartProvider>
            <Router>
              {/* Currently showing the Coming Soon component */}
              <div className="app-container">
                <ComingSoon />
              </div>

              {/* Uncomment the block below to switch back to the full application */}
              {/*
              <RouteChangeTracker />
              <div className="app-container slide-up">
                <Routes>
                  <Route path="/" element={<Home isLoading={showSplash} />} />
                  <Route path="/testing" element={<Testing />} />
                  <Route path="/auth" element={<Auth />} />
                  <Route path="/termsandconditions" element={<TermsAndConditions />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/complete-profile" element={<CompleteProfile />} />
                  <Route path="/shop" element={<Shop />} />
                  <Route path="/catalogo" element={<Catalogo />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/baseProducts/:productId" element={<ProductDetails />} />
                  <Route path="/jewelry/:productId" element={<JewelryDetails />} />
                  <Route
                    path="/admin-panel"
                    element={
                      <ProtectedRoute role="admin">
                        <AdminPanel />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/affiliate-panel"
                    element={
                      <ProtectedRoute role="affiliate">
                        <AffiliatePanel />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/combination/:combinationId" element={<CombinationProductDetails />} />
                </Routes>
              </div>
              */}
            </Router>
          </CartProvider>
        </UserProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

export default App;
