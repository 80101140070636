import React, { useEffect, useState } from 'react';
import './SplashScreen.css'; // Create and style this CSS file as needed
import logo from './Logo2.png'; // Import the logo

const SplashScreen = ({ isVisible }) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 1000); // Show text after 1 second

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`splash-screen ${!isVisible ? 'hidden' : ''}`}>
      <img src={logo} alt="Logo" className="splash-logo" />
      {showText && <h1 className="splash-text">Street Meets Elegance</h1>}
    </div>
  );
};

export default SplashScreen;
