import { createContext, useEffect, useState } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { app } from './firebaseConfig';
import { doc, onSnapshot, getFirestore } from 'firebase/firestore';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(app), async (authUser) => {
      if (authUser) {
        const docRef = doc(getFirestore(app), 'users', authUser.uid);
        onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            const user = {
              uid: authUser.uid,
              email: authUser.email,
              ...userData,
            };
            setUser(user);
          } else {
            setUser(null);
          }
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  );
};
