import React, { createContext, useState, useEffect } from 'react';
import { firestore } from './firebaseConfig';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useContext } from 'react';
import { UserContext } from './UserContext'; // Ensure UserContext is imported

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const { user } = useContext(UserContext); // Get user from UserContext
    const userId = user ? user.uid : null; // Use user's uid or null

  useEffect(() => {
    const cartRef = doc(firestore, `carts/${userId}`);
    const unsubscribe = onSnapshot(cartRef, (doc) => {
      if (doc.exists()) {
        setCartItems(doc.data().items);
      }
    });
    return unsubscribe;
  }, [userId]);

  const updateCartItems = async (newItems) => {
    setCartItems(newItems);
    if (user) {
      const cartRef = doc(firestore, `carts/${user.uid}`);
      await updateDoc(cartRef, { items: newItems });
    }
  };

  const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0); // Total count including quantities
  // Add functionality to update cart items in the context
  const addToCart = (item) => {
    setCartItems([...cartItems, item]);
  };

  return (
    <CartContext.Provider value={{ cartItems, updateCartItems, cartCount }}>
      {children}
    </CartContext.Provider>
  );
};