// ComingSoon.js
import React from 'react';
import './ComingSoon.css';
import Spinner from './Spinner'; // Ensure the path is correct for the Spinner component

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <h1 className="coming-soon-title">Coming Soon</h1>
        <p className="coming-soon-text">We're working hard to bring you a great experience. Stay tuned!</p>
      </div>
      <Spinner /> {/* Loading spinner */}
      <div className="skeleton-loader">
        {/* Example skeleton elements for loading effect */}
        <div className="skeleton skeleton-title"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-button"></div>
      </div>
    </div>
  );
};

export default ComingSoon;
